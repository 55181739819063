import { getScreenSize, Screen } from "constants/breakpoints";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const useBreakpoint = (screen: Screen) => {
  const [isScreen, setIsScreen] = useState(false);
  const isBreakpoint = useMediaQuery(getScreenSize(screen));

  useEffect(() => {
    if (isBreakpoint !== isScreen) {
      setIsScreen(isBreakpoint);
    }
  }, [isBreakpoint, isScreen]);

  return isScreen;
}

export default useBreakpoint;
