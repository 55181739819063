import { useSelector } from "react-redux";
import { ReduxState } from "types";
import dynamic from "next/dynamic";

const ProductDetailsDrawer = dynamic(() => import('./ProductDetailsDrawer'));

const Drawer = () => {
  const { productDetails } = useSelector((state: ReduxState) => state.drawer || {});

  if (productDetails?.isVisible) return <ProductDetailsDrawer data={productDetails.data} />

  return <></>;
};

export default Drawer;
